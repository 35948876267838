import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InteridSiteWebUiSharedModule } from '@interid/interid-site-web/ui-shared';
import { SearchComponent } from './components/search/search.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        RouterModule.forChild([]),
        ReactiveFormsModule,
        InteridSiteWebUiSharedModule,
    ],
    declarations: [
        SearchComponent,
    ],
    exports: [
        SearchComponent,
    ],
})
export class InteridSiteWebUiSharedSearchModule {}
