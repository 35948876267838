import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output, signal,
    ViewChild,
    Inject,
    PLATFORM_ID
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {Source, ViewBreakpointsShared} from '@interid/interid-site-shared';
import {fromEvent, Subject} from 'rxjs';
import {filter,takeUntil} from 'rxjs/operators';
import {UrlBuilderService, ViewBreakpointsService} from '@interid/interid-site-web/core';
import { isPlatformBrowser } from '@angular/common';

interface State {
    showPlaceholder: boolean;
    form: UntypedFormGroup;
    isFocused: boolean;
}

interface FormValue {
    queryString: string;
}

@Component({
    selector: 'app-shared-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
    @ViewChild('input') inputRef!: ElementRef<HTMLInputElement>;
    @ViewChild('placeholderContainer') placeholderContainer!: ElementRef<HTMLDivElement>;

    @Output() nextQuery = new EventEmitter<string | undefined>();

    private ngOnDestroy$ = new Subject<void>();
    public state = signal<State>({
        form: this.fb.group({
            queryString: [''],
        }),
        showPlaceholder: true,
        isFocused: false
    });

    constructor(
        @Inject(PLATFORM_ID) private readonly platformId: Object,
        private fb: UntypedFormBuilder,
        private router: Router,
        private urlBuilder: UrlBuilderService,
        private route: ActivatedRoute,
        private readonly viewBreakpoints: ViewBreakpointsService,
    ) {}

    ngOnInit(): void {

        this.route.queryParams
            .pipe(
                takeUntil(this.ngOnDestroy$)
            )
            .subscribe((params) => {
                const queryString = params['q'];
                if (queryString) {
                    this.patchFormValue = {
                        queryString: queryString,
                    };
                    if(this.hasQueryString) {
                        this.state.update((prev) => ({
                            ...prev,
                            showPlaceholder: false,
                        }));
                    }
                }
            });
        if (isPlatformBrowser(this.platformId)) {
            fromEvent<MouseEvent>(document, 'click')
                .pipe(
                    filter((event) => {
                        const target = event.target as HTMLElement;
                        return !this.placeholderContainer.nativeElement.contains(target);
                    }),
                    takeUntil(this.ngOnDestroy$)
                )
                .subscribe(() => {
                    if(!this.hasQueryString) {
                        this.state.update((prev) => ({
                            ...prev,
                            showPlaceholder: true,
                        }));
                    }
                });
        }


    }

    set patchFormValue(formValue: Partial<FormValue>) {
        this.state().form.patchValue(formValue);
    }

    get checkMobile(): boolean {
        return [ViewBreakpointsShared.Layout.Desktop, ViewBreakpointsShared.Layout.Wide].includes(this.viewBreakpoints.currentLayout)
    }

    get hasQueryString(): boolean {
        const value = this.state().form.get('queryString')?.value || '';
        return value.toString().trim().length > 0;
    }

    onPlaceholderClick(): void {
        this.state.update((prev) => ({
            ...prev,
            showPlaceholder: false,
        }));
        const observer = new MutationObserver((mutations, mutationInstance) => {
            if (this.inputRef?.nativeElement) {
                this.focus();
                mutationInstance.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    }

    focus(): void {
        this.inputRef?.nativeElement?.focus();
    }

    navigateToSearch(): void {
        if (!this.hasQueryString) return;

        const route = this.urlBuilder.routerLink({
            type: Source.ProductSearch,
            payload: { queryString: this.state().form.get('queryString')?.value },
        });

        this.router.navigate(route.route, { queryParams: route.queryParams });
    }

    ngOnDestroy(): void {
        this.ngOnDestroy$.next();
        this.ngOnDestroy$.complete();
    }
}
