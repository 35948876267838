<div class="c">
  <div class="c-header">
    <app-shared-search></app-shared-search>
    <div class="c-header-icon" (click)="close()">
      <img loading="lazy" src="/assets/images/close.svg" />
    </div>
  </div>

  @if (!!state().selectedCategory) {
    <div class="c-header-sidebar">
      <div class="c-header-frame-1" (click)="toParent(state().selectedCategory)">
        <img loading="lazy" src="/assets/images/fi_arrow-left.svg" />
        <span>{{ state().selectedCategory.menuTitle }} </span>
      </div>
      <div class="c-header-frame-div"></div>
      <div class="c-header-frame-2" (click)="toCategory(state().selectedCategory)">
        <span>Все</span>
        <img loading="lazy" src="/assets/images/chevron-right.svg" />
      </div>
    </div>
  }

  <div class="c-categories">
    @if (!!state().selectedCategory) {
      @for (seoLink of categoriesSeoLinks; track trackById($index, seoLink)) {
        <div class="c-category" (click)="selectSeoLink(seoLink)">
          <span>
            {{ seoLink.title }}
          </span>
        </div>
      }
    }

    @for (rootCategory of productCategories; track trackById($index, rootCategory)) {
      <div class="c-category" (click)="selectCategory(rootCategory)">
        @if (!!rootCategory.menuIcon) {
          <div class="c-category-icon"><app-shared-attachment-image [attachment]="rootCategory.menuIcon" [maxImageWidth]="24"></app-shared-attachment-image></div>
        }
        <span>
          {{ rootCategory.menuTitle }}
        </span>
        @if (hasChilds(rootCategory)) {
          <div class="chevron-right">
            <img loading="lazy" src="/assets/images/chevron-right.svg" />
          </div>
        }
      </div>
    }

    @if (!!!state().selectedCategory) {
      <a class="c-category" [routerLink]="['/sales']" (click)="close()">
        <div class="c-category-icon"><img loading="lazy" src="/assets/images/catalog_menu_sales.svg" /></div>
        <span> Акции и скидки </span>
      </a>
      <a class="c-category" [routerLink]="['/about/company']" (click)="close()">
        <div class="c-category-icon"><img loading="lazy" src="/assets/images/catalog_menu_logo.svg" /></div>
        <span> О компании «Inter ID» </span>
      </a>
    }
  </div>
</div>
