import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HostHeaderHttpInterceptor, JwtHttpInterceptor, ReplacesHttpInterceptor, UserAgentHttpInterceptor, UserCountersHttpInterceptor } from '@interid/interid-site-web/core';


@NgModule({
    imports: [
        HttpClientModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: UserAgentHttpInterceptor,
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: JwtHttpInterceptor,
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: ReplacesHttpInterceptor,
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: UserCountersHttpInterceptor,
        },
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: HostHeaderHttpInterceptor,
        },
    ],
    exports: [
        HttpClientModule,
    ],
})
export class InteridSiteWebCoreHttpModule {}
